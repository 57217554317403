<template>
  <div class="mb-8 text-g7-blue">
    <HomeHeroSlider class="mt-2 sm:mt-4 lg:mt-auto" />
    <MagazinSearch v-model="filter" :search="search" @search="getArticles" />

    <hr class="hidden border-2 lg:block" />

    <div>
      <Headline
        v-if="noData && !pending"
        class="mx-4 my-5 text-center"
        size="3XL"
        :title="'Keine Artikel vorhanden'"
      />
      <LoadingSpinner v-if="pending" class="z-50 mx-auto my-4" />

      <template v-if="articles.length > 0 && !pending">
        <div
          v-for="(article, index) in articles"
          :key="article.article_info.slug"
        >
          <MagazinArticle class="m-4" :article="article" :index="index + 1" />

          <hr class="mx-4 hidden border sm:block" />

          <CardGroup
            v-if="index === 1"
            v-slot="{ item }"
            :mobile-slider="true"
            class="my-5 bg-white md:border md:bg-slate-100"
            :title="data.cruise_tipps_title"
            :items="data.cruise_tipps"
          >
            <Infocard class="mx-8 md:mx-0" :item="item" />
          </CardGroup>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { defaultApiHeaders } from "~/composables/useGo7seasApi";
import type { Article } from "~~/types/magazin";
import type { ArticlesResponse, MagazinHomeResponse } from "~~/types/response";

const { data } = await useGo7seasApi<MagazinHomeResponse>(
  apiUrl("/catalog/magazin/home")
);

updateDefaultLayout(data.value);
useDiaShow().set(data.value?.dia_show.dias ?? []);

const filter = magazinFilter.value;
filter.category =
  filter.category ?? data.value.search.category_selection.options[0];
filter.search = filter.search ?? "";

const search = computed(() => data.value.search);
const articles: Ref<Array<Article>> = ref([]);
const pending: Ref<boolean> = ref(false);
const noData: Ref<boolean> = ref(false);
async function getArticles() {
  pending.value = true;
  let query = "";
  if (filter.search !== "") {
    query += `&search_text=${filter.search}`;
  }
  const data = await $fetch<ArticlesResponse>(
    apiUrl(
      `/catalog/magazin/articles?category_id=${filter.category.value}${query}`
    ),
    {
      headers: defaultApiHeaders(),
    }
  );
  if (data.articles.length === 0) {
    noData.value = true;
  } else if (data.articles.length > 0) {
    noData.value = false;
  }
  articles.value = data.articles;
  pending.value = false;
}

onMounted(() => {
  getArticles();
});
</script>
